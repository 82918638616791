:root {
  --aside-bg: #fff;
}

/*
Reset / normalize 

Basert på
https://github.com/hankchizljaw/modern-css-reset
https://www.joshwcomeau.com/css/custom-css-reset/
https://meyerweb.com/eric/tools/css/reset/
*/

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  background-color: var(--bg);
  color: var(--color);
}

html,
body,
#root {
  height: 100%;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  font-family: var(--font-family);
  font-size: var(--font-size-medium);
  /*line-height: var(--line-height, 1.5);*/
}

html:focus-within {
  scroll-behavior: smooth;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}
button {
  min-width: 40px;
  min-height: 40px;
}

/* Avoid text overflows */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

fieldset {
  border: none;
}

#root,
#__next {
  isolation: isolate;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

[hidden] {
  display: none;
}

h1 {
  font-size: var(--font-size-xxlarge);
}
h2 {
  font-size: var(--font-size-xlarge);
}
h3 {
  font-size: var(--font-size-large);
}
h4 {
  font-size: var(--font-size-large);
  font-weight: 200;
}

svg {
  fill: currentColor;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
  transition: 0.2s;
}

#root {
  display: grid;
  grid: [stack] 1fr / min-content [stack] 1fr;
  min-block-size: 100vh;
  overflow: hidden;
}
#root > :is(aside, main) {
  grid-area: stack;
}
#root > main {
  display: grid;
  background-color: var(--bg);
  height: 100vh;
  margin: 0 auto;
  /*min-width: 100vw;*/
  max-width: 1440px;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "article"
    "footer";
}
@supports (height: 100dvh) {
  #root > main {
    height: 100dvh;
  }
}

#root > main > header {
  grid-area: header;
  background-color: var(--header-bg);
  border-bottom: 1px solid rgba(125, 125, 125, 0.1);
  display: flex;
  justify-content: space-between;
}

#root > main > article {
  width: 100%;
  grid-area: article;
  overflow: auto;
  background-color: var(--article-bg);
}

#root > main > footer {
  grid-area: footer;
  background-color: var(--footer-bg);
  border-top: 1px solid rgba(125, 125, 125, 0.1);
  /*box-shadow: 0 5px 15px rgba(0,0,0,0.2);*/
  display: flex;
  justify-content: space-between;
}

#root > aside {
  /*display: none;*/
  --easeOutExpo: cubic-bezier(0.16, 1, 0.3, 1);
  --duration: 100ms;
  display: grid;
  grid-template-columns: [escape] 1fr [nav] auto;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  max-height: 100vh;
  overflow: hidden auto;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  visibility: hidden;
  /* not keyboard accessible when closed */
  transform: translateX(100vw);
  will-change: transform;
  transition: transform var(--duration) var(--easeOutExpo),
    visibility 0s linear var(--duration);
  backdrop-filter: blur(5px);
}
#root {
  aside {
    nav {
      background-color: var(--aside-bg);
      display: flex;
      flex-direction: column;
      padding: 1rem 3rem;
      line-height: 3rem;

      a {
        text-decoration: none;
        color: var(--black);
      }
    }
  }
}

#root > aside.open {
  visibility: visible;
  transform: translateX(0);
  transition: transform var(--duration) var(--easeOutExpo);
}

#sidenav-button,
#sidenav-close {
  background-color: rgba(125, 125, 125, 0.2);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
}

/*
CUBE CSS
*/

/*
Composition
*/

.flow > * + * {
  margin-top: var(--flow-space, 1em);
}

/*
Utlitity
*/
.wrapper {
  margin-inline: auto;
  padding-inline: 1rem;
  max-width: 60rem;
}

/*
Block
*/

/*
Exception
*/
