:root {
  color-scheme: light dark;

  /**/
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";

  --font-family-headings: "Montserrat";
  --font-family: "Roboto";

  --font-size-small: clamp(0.9rem, 0.41vw + 0.8rem, 1.13rem);
  --font-size-medium: clamp(1.13rem, 0.68vw + 0.95rem, 1.5rem);
  --font-size-large: clamp(1.41rem, 1.08vw + 1.14rem, 2rem);
  --font-size-xlarge: clamp(1.76rem, 1.65vw + 1.35rem, 2.67rem);
  --font-size-xxlarge: clamp(2.2rem, 2.46vw + 1.58rem, 3.55rem);
  /*
  https://www.fluid-type-scale.com/calculate?minFontSize=18&minWidth=400&minRatio=1.25&maxFontSize=24&maxWidth=1280&maxRatio=1.333&steps=small%2Cmedium%2Clarge%2Cxlarge%2Cxxlarge&baseStep=medium&prefix=font-size&decimals=2&useRems=on&previewFont=Inter
  */

  /*
  COLORS
  */
  --white: #fff;
  --black: #303030;

  --bg: #dbc7ac;
  --bg-light: #fff0dc;
  --bg-dark: #ab967a;
  --color: var(--black);

  --primary: var(--white);
  --primary-bg: #00468e;
  --primary-bg-drop-shadow: drop-shadow(0 3px 0 #00346a)
    drop-shadow(0 6px 0 rgba(0, 0, 0, 0.15));
  --primary-font-family: var(--font-family-headings);

  /*
  buttons
  */
  --border-radius-default: var(--border-radius-full);

  /* top | right | bottom | left */
  --primary-border-radius: 10% 30% 50% 70%;
  --primary-border-width: 3px 3px 3px 3px;
  --primary-border-style: dashed groove none dotted;
  --primary-border-color: red yellow green hsla(60, 90%, 50%, 0.8);
  /* width | style | color */
  --border: medium dashed green;

  --outline: 3px dashed #32a1ce;
  --outline-offset: 3px;

  /* offset-x | offset-y | blur-radius | spread-radius | color */
  --box-shadow: 3px 3px red, -1em 0 0.4em olive;
  --text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
  /* offset-x | offset-y | blur-radius | color */
  --filter: drop-shadow(0 0 0.75rem crimson);

  /*--primary: #000;*/
  /*--primary-border: #ccc;*/
  /*--primary-bg: rgb(134, 96, 96);*/

  --secondary: #666;
  --secondary-border: #ccc;
  --secondary-bg: transparent;

  --spacing-small: 0.75rem;
  --spacing-medium: 1.5rem;
  --spacing-large: 2rem;
  --spacing-xlarge: 2.67rem;
  --spacing-xxlarge: 3.55rem;
  --spacing: var(--spacing-medium);

  --padding-small: 0.75rem;
  --padding-medium: 1.5rem;
  --padding-large: 2rem;
  --padding-xlarge: 2.67rem;
  --padding-xxlarge: 3.55rem;
  --padding: var(--padding-medium);

  --primaryH: 78;
  --primaryS: 100%;
  --primaryL: 42%;
  /*--primary-bg: #efefef; */
  --secondaryH: 208;
  --secondaryS: 14%;
  --secondaryL: 21%;
  --secondary-bg: #adc19e;

  --max-desktop-width: 1440px;

  /*
  --primary-bg: #fff;
  --secondary-bg: #eee;

  --main-bg-color: #efefef;
  --main-color: #303030;

  --primary-color: #00f;
  --secondary-color: #ccc;
  */

  --correct-color: #7cb995;
  --incorrect-color: #d45430;

  --border-radius-small: 2px;
  --border-radius-medium: 5px;
  --border-radius-large: 10px;
  --border-radius-full: 9999px;

  /*--border-radius-default: var(--border-radius-large, 0);*/

  /* Status colors */
  --error: #d00;
  --warning: #ec0;
  --info: #369;
  --correct: #080;

  --mc-text: var(--white);
  --mc-filltext: var(--black);
  --mc-color: #4e6689;
  --mc-fill: #263d51;
  --mc-correct: var(--correct-color);
  --mc-wrong: var(--incorrect-color);
  --mc-border-radius: 0.75rem;
  --mc-inline-padding: 1.7rem;

  --feedback-padding: 1rem 1.05rem;
  --feedback-width: 25rem;
}

html[data-theme="dark"] {
  --primary-bg: #000;
}

@media (prefers-color-scheme: dark) {
  html {
    --primary-bg: #000;
  }
  img {
    filter: brightness(0.8) contrast(1.2);
  }
}

/*--- USING THE CUSTOM PROPS ---*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto&display=swap");

html {
  scroll-behavior: smooth;
}
html,
body {
  background-color: var(--bg, "#fff");
  font-family: var(--font-family);
  font-size: var(--font-size-medium);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-headings);
}

blockquote {
  background-color: var(--bg, "#fff");
  border-left: 10px solid var(--primary-bg);
  margin: 1.5em 10px;
  padding: 0.5em 20px;
  font-size: var(--font-size-medium);
}

.textpageImage {
  position: relative;
  width: 100%;
  max-width: var(--max-desktop-width);
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  width: 100%;
  max-width: 30rem !important;
  margin: 0.5rem 1rem;
}
